.App {
  text-align: center;
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

.cross-hair {
  background-color: #333;
  color: white;
  padding: 5px;
  border-radius: 5px;
  width: 12em;
  text-align: center;
}

p {
  font-size: 1rem;
}

.cross-hair p {
  margin: 0
}

.mw-20rem {
  max-width: 20rem !important;
}

.w-25rem {
  width: 25rem !important;
}

nav.nav-tabs {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

nav.nav-tabs a {
  width: 50%;
  text-align: center;
}

.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
  background-color: #007bff !important;
}

.container {
  max-width: 800px !important;
}
